import React from "react";
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {    
  const { t } = useTranslation();

      return (
        <footer className="footer" role="contentinfo">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0">
              <h3>{t('footer_about_title')}</h3>
              <p>{t('footer_about')}</p>
              <p className="social">
                <a href="https://www.linkedin.com/company/92569863/admin/feed/posts/" target="_blank"><span className="bi bi-linkedin" /></a>
              </p>
            </div>
            <div className="col-md-7 ms-auto">
              <div className="row site-section pt-0">
                <div className="col-md-6 mb-4 mb-md-0">
                  <h3>{t('navigation')}</h3>
                  <ul className="list-unstyled">
                    <li><HashLink to={"/#services"}>{t('services')}</HashLink></li>
                    <li><HashLink to={"/#howwework"}>{t('how_we_work')}</HashLink></li>
                    <li><HashLink to={"/drupal-migrate#hero"}>{t('migrate_drupal_cta')}</HashLink></li>
                    <li><HashLink to={"/#tools-platforms"}>{t('tools_platforms')}</HashLink></li>
                    <li><HashLink to={"/#contact"}>{t('lets_talk_menu')}</HashLink></li>
                  </ul>
                </div>
                <div className="col-md-6 mb-4 mb-md-0">
                  <ul className="list-unstyled">
                  
                  <h3>{t('lets_talk')}</h3>
                  <ul className="list-unstyled">
                    <li><a href="#">Silvio Ruggieri 2700, CABA, Argentina</a></li>
                    <li><a href="mailto:hello@dropitlab.com">hello@dropitlab.com</a></li>
                    <li><a href="https://www.linkedin.com/company/92569863/admin/feed/posts/" target="_blank">Linkedin</a></li>
                  </ul>
           
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-md-7">
              <p className="copyright">© Copyright DropitLab 2024. All Rights Reserved</p>
              <div className="credits">
                {/*
            All the links in the footer should remain intact.
            You can delete the links only if you purchased the pro version.
            Licensing information: https://bootstrapmade.com/license/
            Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=SoftLand
          */}
              </div>
            </div>
          </div>
        </div>
      </footer>
      );
}

export default Footer;