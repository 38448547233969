import { Suspense } from "react";

import Header from "./components/header";
import Hero from "./components/hero";
import PreHeader from "./components/pre-header";
import Clients from "./components/clients";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Counts from "./components/counts";
import Tech from "./components/tech";
import Whatwedo from "./components/whatwedo";
import Timer from "./components/timer";

import Carousel from "./components/carousel";
import LogoCarousel from "../src/components/logocarousel";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { FaCode, FaServer, FaCloud } from "react-icons/fa";
import ContactHero from "../src/components/contacthero";
import Services from "./components/services";

export const HomePage = () => {
  const { t } = useTranslation();
  const logos = [
    {
      src: require("./assets/img/logos/asu.png"),
      alt: "Arizona State University",
    },
    {
      src: require("./assets/img/logos/yale.png"),
      alt: "Yale University",
    },
    { src: require("./assets/img/logos/unesco-logo.png"), alt: "Unesco" },
    { src: require("./assets/img/logos/WWE-logo.jpg"), alt: "WWE" },
    { src: require("./assets/img/logos/apple-logo.png"), alt: "Apple" },
    {
      src: require("./assets/img/logos/logo-estee.png"),
      alt: "Estee Lauder",
    },
    { src: require("./assets/img/logos/anfler.png"), alt: "Anfler" },
    {
      src: require("./assets/img/logos/naciones-unidas.png"),
      alt: "ONU",
    },
    { src: require("./assets/img/logos/ustires.png"), alt: "ONU" },
  ];
  const services = [
    {
      icon: <FaCode />,
      title: "Custom Drupal Development",
      description: "Tailored solutions using the power of Drupal.",
    },
    {
      icon: <FaServer />,
      title: "Drupal Hosting & Maintenance",
      description: "Reliable hosting to keep your site running smoothly.",
    },
    {
      icon: <FaCloud />,
      title: "Drupal Cloud Integration",
      description: "Seamless cloud integrations for scalability.",
    },
    {
      icon: <FaCode />,
      title: "E-commerce Solutions",
      description: "Powerful e-commerce sites built on Drupal.",
    },
    {
      icon: <FaServer />,
      title: "Site Optimization",
      description: "Performance improvements for faster load times.",
    },
  ];

  return (
    <Suspense fallback="loading">
      <Helmet>
        <title>Dropitlab - Turning ideas into digital experiences</title>
        <meta
          name="description"
          content="We are a leading company in web solutions and mobile applications based in Buenos Aires, Argentina. Specializing in Drupal, we are ready to take your platform to the next level."
        />
        <meta
          name="keywords"
          content="Software development, Application development, Coding, Programming, Agile development, Software engineering, Development frameworks, Software architecture, Code optimization, Version control,
                Drupal CMS, Drupal development, Drupal modules, Drupal theming, Drupal migration, Drupal 9, Drupal community, Drupal security, Drupal best practices, Drupal customization,
                Drupal migration tools, Upgrade to Drupal 9, Drupal version migration, Content migration in Drupal, Migrate from Drupal 7 to 9, Data migration strategies, Module migration in Drupal, Drupal site migration, Planning Drupal migration, Smooth Drupal migration"
        />
      </Helmet>

      <div className="App">
        <Header />
        <Hero />

        <main id="main">
          {/*<Carousel title="Our Services" services={services} />*/}
          <Services />
          <Timer />
        
           <LogoCarousel logos={logos} homepage={true}/>
          <Whatwedo />
          <Tech />

          <Counts />
          <Clients />
          <Contact />
        </main>

        <Footer />
      </div>
    </Suspense>
  );
};
