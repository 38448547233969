import React from "react";
import "../css/homehero.css"; // Import the updated styles

const ContactHero = () => {
  return (
    <>
      <div className="contact-info text-center">
          <p className="email-phone">
            <a href="mailto:hello@dropitlab.com" className="contact-item">hello@dropitlab.com <i className="bi bi-envelope"></i></a>  
            <a href="tel:+1234567890" className="contact-item"> <i className="bi bi-telephone"></i> +1 234 567 890</a>
          </p>
      </div>
    </>
  );
}

export default ContactHero;