import { m } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Timer = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const expirationDate = new Date("2025-01-05");
    const difference = expirationDate - new Date();

    let timeLeft = {};

    if (difference > 0) {
      const seconds = Math.floor((difference / 1000) % 60);
      const formattedSeconds = seconds.toString().padStart(2, "0");

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const formattedDays = days.toString().padStart(2, "0");

      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const formattedHours = hours.toString().padStart(2, "0");

      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const formattedMinutes = minutes.toString().padStart(2, "0");

      timeLeft = {
        days: formattedDays,
        hours: formattedHours,
        minutes: formattedMinutes,
        seconds: formattedSeconds,
      };
     }

    return timeLeft;
  }

  return (
    <section className="section timer">
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <h1
                className="section-heading-drupal"
              >
                {t("drupal_expires_soon")}
              </h1>
              <img
                src="img/drupal-logo.svg"
                className="img-fluid"
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col-12 d-flex align-items-center justify-content-center spanhours"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              {timeLeft.days > 0 && (
                <div>
                  <span>{timeLeft.days}</span>
                  <span className="inside-timer">{t("days")}</span>
                </div>
              )}
              {timeLeft.hours > 0 && (
                <div>
                  <span>{timeLeft.hours}</span>
                  <span className="inside-timer"> {t("hours")} </span>
                </div>
              )}
              {timeLeft.minutes >= 0 && (
                <div>
                  <span>{timeLeft.minutes}</span>
                  <span className="inside-timer"> {t("minutes")} </span>
                </div>
              )}
              {timeLeft.seconds >= 0 && (
                <div>
                  <span>{timeLeft.seconds.toString().padStart(2, "0")}</span>
                  <span className="inside-timer"> {t("seconds")} </span>
                </div>
              )}
              {Object.keys(timeLeft).length === 0 && <span>Expired</span>}
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center" style={{ fontSize: "20px" }}>
              <p
                className="textDrupalDescription"
              >
                {t("drupal_timer_one")}{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    backgroundColor: "transparent",
                  }}
                >
                  {t("drupal_timer_two")}
                </span>
                {t("drupal_timer_three")} <br />
                <a href="/#contact" style={{ color: "black" }}><em>{t("drupal_timer_four")} </em></a> {t("drupal_timer_five")} 
              </p>
            </div>
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <a
                  href="/drupal-migrate"
                  className="btn d-inline-flex align-items-center btn-migration-process"
                >
                  {t("our_migration_process")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timer;
