import React from "react";
import "../css/logocarousel.css";
import { useTranslation } from "react-i18next";

const LogoCarousel = ({ logos, homepage = null }) => {
  const loopedLogos = [...logos, ...logos];
  const { t } = useTranslation();

  return (
    <section className="section">
      {homepage && (
        <div className="row">
          <div className="col">
            <h2
              className="section-heading text-center mb-5"
              data-aos="fade-down"
              data-aos-delay={100}
            >
              {t("trusted_by")}
            </h2>
          </div>
        </div>
      )}
      <div className="container" >
        <div className={`logo-carousel-container ${homepage ? "homepage" : ""}`}>
          <div className="logo-carousel-track">
            {loopedLogos.map((logo, index) => (
              <div className="logo-item" key={index}>
                <img
                  src={logo.src}
                  alt={logo.alt}
                  title={logo.alt}
                  className="logo-image"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogoCarousel;
