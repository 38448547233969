import Carousel from "./carousel";
import { FaCode, FaServer, FaCloud } from "react-icons/fa";
const Projects = () => {

  const services = [
    {
      image: {
        src: require("../assets/img/asu-page.png"),
        alt: "ASU Page",
      },
      title: "ASU PAGE",
      description: "Tailored solutions using the power of Drupal.",
    },
    {
      icon: <FaServer />,
      title: "Drupal Hosting & Maintenance",
      description: "Reliable hosting to keep your site running smoothly.",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{
              maxWidth: "600px",
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUp",
            }}
          >
            <h6 className="section-title bg-white text-center text-primary px-3">
              Our Projects
            </h6>
            <h1 className="display-6 mb-4">
              Learn More About Our Complete Projects
            </h1>
          </div>
          <Carousel services={services} />
        </div>
      </div>
    </>
  );
};
export default Projects;
