import React, { useState, useEffect } from "react";
import "../css/servicecarousel.css"; // Import the updated styles

const Carousel = ({ title, services }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCards = 3; // Number of cards visible at a time

  // Function to auto-move the carousel
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + visibleCards >= services.length ? 0 : prevIndex + 1
      );
    }, 3000); // Moves every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [services.length, visibleCards]);

  // Manual navigation
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + visibleCards >= services.length ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? services.length - visibleCards : prevIndex - 1
    );
  };

  return (
    <section id="services" className="section">
      <div className="service-carousel-container">
        {title && <h2 className="carousel-title">{title}</h2>}
        <div className="carousel-wrapper">
          <button className="carousel-button prev" onClick={handlePrev}>
            &#8249;
          </button>
          <div className="carousel-content-wrapper">
            <div
              className="carousel-content"
              style={{
                transform: `translateX(-${
                  (currentIndex * 100) / visibleCards
                }%)`,
              }}
            >
              {services.map((service, index) => (
                <div className="carousel-card" key={index}>
                  {service.icon ? (
                    <div className="service-icon">{service.icon}</div>
                  ) : (
                    <>
                      <div
                        className="project-item border rounded h-100 p-4 service-image"
                      >
                        <div className="position-relative mb-4">
                          <img
                            className="img-fluid rounded"
                            src={service.image.src}
                            alt={service.image.alt}
                          />
                          <a href="img/project-8.jpg" data-lightbox="project">
                            <i className="fa fa-eye fa-2x"></i>
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                  <h3 className="service-title">{service.title}</h3>
                  <p className="service-description">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
          <button className="carousel-button next" onClick={handleNext}>
            &#8250;
          </button>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
