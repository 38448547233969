import "../css/services.css"; // Import the updated styles
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();

  return (
    <section className="section" id="services">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="section-heading text-center mb-4" >{t('our_services')}</h2>
            <p className="landing-section-text">{t('our_services_description')}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <div className="serviceBox">
              <div className="service-icon">
                <span>
                  <i className="bi bi-globe"></i>
                </span>
              </div>
              <h3 className="title">Website Development</h3>
              <p className="description">
                {t('website_development_desc')}
              </p>
              <a href="#" className="read-more">
                Read More
              </a>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="serviceBox">
              <div className="service-icon">
                <span>
                  <i className="bi bi-rocket"></i>
                </span>
              </div>
              <h3 className="title">Mobile & App.</h3>
              <p className="description">
                {t('website_mobile_app')}
              </p>
              <a href="#" className="read-more">
                Read More
              </a>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="serviceBox">
              <div className="service-icon">
                <span>
                  <i className="bi bi-code"></i>
                </span>
              </div>
              <h3 className="title">Drupal 7 EOL</h3>
              <p className="description">
                {t('drupal_eol')}
              </p>
              <a href="/drupal-migrate#hero" className="read-more">
                Read More
              </a>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="serviceBox">
              <div className="service-icon">
                <span>
                  <i className="bi bi-briefcase"></i>
                </span>
              </div>
              <h3 className="title">User-Centered Design</h3>
              <p className="description">
                {t('user_centered_desc')}
              </p>
              <a href="#" className="read-more">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
